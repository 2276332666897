// Store
import store from "@/store";

export interface AppCountyCreateIndex {
  organization_id: string | string[];
  content: {
    title: string;
  };
}

export class AppCountyCreateIndex implements AppCountyCreateIndex {
  organization_id: string | string[];
  content: {
    title: string;
  };

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      title: "",
    };
  }
}